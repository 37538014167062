import auth0/client as auth0
import bright
import data/connector
import data/content_library
import data/data_source
import data/model.{type Model}
import data/model/computed
import data/model/data.{type Data, Data}
import data/msg.{type Msg}
import data/notification.{type Notification, Notification}
import data/project
import data/proposal
import data/question.{type Question, Question}
import data/route
import data/translate
import data/ui/loading
import data/user
import ds
import elements/editor/editor/menu
import elements/editor/view as editor
import frontend/effects
import frontend/effects/content_library as content_library_effects
import frontend/effects/copilot as copilot_effects
import frontend/effects/dom
import frontend/effects/organization as organization_effects
import frontend/effects/project as project_effects
import frontend/effects/proposal as proposal_effects
import frontend/effects/question as question_effects
import frontend/effects/user as user_effects
import frontend/effects/window
import frontend/ffi
import frontend/init
import frontend/middleware.{require_not_modifying_question}
import frontend/websocket
import frontend/websocket/messages
import gleam/bool
import gleam/dict
import gleam/function
import gleam/json
import gleam/list
import gleam/option.{Some}
import gleam/pair
import gleam/result
import gleam/string
import grille_pain
import grille_pain/lustre/toast
import lustre
import lustre/effect
import modem
import plinth/javascript/global
import sketch/magic
import update/connectors
import update/content_library.{update as update_content_library} as _
import update/proposal_builder.{update as update_proposal_builder} as _
import view/components/translated_text
import view/layout
import view/login
import view/slack_login

pub fn main() {
  let assert Ok(_) = ds.setup()
  let assert Ok(_) = editor.register()
  let assert Ok(_) = menu.register()
  let assert Ok(_) = grille_pain.simple()
  let assert Ok(_) = init.sentry()
  let assert Ok(flags) = init.flags()
  let assert Ok(stylesheet) = init.sketch()
  let assert Ok(dispatch) =
    view(_, stylesheet)
    |> lustre.application(init.init, update, _)
    |> lustre.start("#app", flags)
  let assert Ok(_) = websocket.setup(dispatch)
}

fn update(model: Model, msg: Msg) -> #(Model, effect.Effect(Msg)) {
  use model <- bright.start(model)
  model
  |> bright.update(update_data(_, msg))
  |> bright.compute(computed.displayed_questions)
  |> bright.compute(computed.projects)
  |> bright.lazy_compute(computed.sources_selector, computed.sources)
  |> bright.schedule(model.replace_proposal_question_page)
}

fn update_data(data: Data, msg: Msg) -> #(Data, effect.Effect(Msg)) {
  case msg {
    msg.Back -> #(data, modem.back(1))

    msg.UserToggledCollapsedNavbar -> {
      let collapsed_navbar = !data.collapsed_navbar
      Data(..data, collapsed_navbar:)
      |> pair.new({
        use _ <- effect.from
        json.bool(collapsed_navbar)
        |> ffi.write_local_storage("collapsed-navbar")
        |> result.unwrap_both
      })
    }

    msg.UserToggledCollapsedProject(project:) -> {
      dict.get(data.opened_projects, project.id)
      |> result.unwrap(True)
      |> bool.negate
      |> dict.insert(data.opened_projects, project.id, _)
      |> fn(opened_projects) { Data(..data, opened_projects:) }
      |> pair.new(effect.none())
    }

    msg.ApplicationPerformedRequest(id) -> {
      let running_requests = [id, ..data.running_requests]
      let data = Data(..data, running_requests:)
      #(data, effect.none())
    }

    msg.ApplicationCompletedRequest(id) -> {
      let is_id = fn(i) { i != id }
      let running_requests = list.filter(data.running_requests, is_id)
      let data = Data(..data, running_requests:)
      #(data, effect.none())
    }

    msg.UserClickedProposal(proposal, sheet) -> {
      proposal.project_id
      |> route.ProjectProposals(route.ShowProposal(proposal.id, sheet, 0))
      |> route.Projects
      |> case data.route {
        route.Projects(route.ProjectProposals(_, route.ShowProposal(pid, ..)))
          if pid == proposal.id
        -> route.replace
        _ -> route.push
      }
      |> pair.new(data, _)
    }

    msg.UserClickedAddProposal(project:) -> {
      route.AddProposal(project_id: Some(project.id), loading: False)
      |> route.Projects
      |> route.push
      |> pair.new(data, _)
    }

    msg.UserDeletedProposal(id) -> {
      let found = list.key_find(data.proposals, id) |> result.is_ok
      use <- bool.guard(when: !found, return: #(data, effect.none()))
      data.proposals
      |> list.filter(fn(proposal) { { proposal.1 }.id != id })
      |> fn(proposals) { Data(..data, proposals:, display_modal: data.NoModal) }
      |> pair.new(proposal_effects.delete_proposal(data, id))
    }

    msg.UserDeletedQuestionInProposal(proposal_id, question_id) -> {
      let found =
        model.find_question(data, proposal_id, question_id) |> result.is_ok
      use <- bool.guard(when: !found, return: #(data, effect.none()))
      let updated_model = data.remove_question(data, proposal_id, question_id)
      Data(..updated_model, display_modal: data.NoModal)
      |> data.empty_popup("ai-" <> question_id)
      |> pair.new(proposal_effects.delete_question_in_proposal(
        data,
        proposal_id,
        question_id,
      ))
      |> pair.map_second(fn(e) {
        effect.batch([model.unsubscribe_more_proposal(data), e])
      })
    }

    msg.UserDeletedDataSource(id) -> {
      let by_id = fn(s: data_source.DataSource) { s.id == id }
      case
        list.append(
          data.content_library.non_qna_sources,
          data.content_library.qna_sources,
        )
        |> list.find(by_id)
      {
        Error(_) -> #(data, effect.none())
        Ok(data_source) -> {
          let updated_content_library = case data_source.kind {
            connector.SteerlabQna -> {
              data.content_library
              |> content_library.set_qna_sources({
                data.content_library.qna_sources
                |> list.filter(fn(s) { s.id != id })
              })
            }
            _ -> {
              data.content_library
              |> content_library.set_non_qna_sources({
                data.content_library.non_qna_sources
                |> list.filter(fn(s) { s.id != id })
              })
            }
          }
          updated_content_library
          |> data.set_content_library(data, _)
          |> pair.new(content_library_effects.delete_data_source(data, id))
        }
      }
    }

    msg.UserDisplayedDeleteProposalModal(id) -> {
      Data(..data, display_modal: data.DeleteProposal(id))
      |> pair.new({
        dom.subscribe_dom_click(fn(_) { msg.None }, msg.UserClosedModal)
      })
    }

    msg.UserDisplayedDeleteDataSourceModal(id) -> {
      Data(..data, display_modal: data.DeleteDataSource(id))
      |> pair.new({
        dom.subscribe_dom_click(fn(_) { msg.None }, msg.UserClosedModal)
      })
    }

    msg.UserDisplayedDeleteQuestionInProposalModal(proposal_id, question_id) -> {
      Data(
        ..data,
        display_modal: data.DeleteQuestionInProposal(proposal_id, question_id),
      )
      |> pair.new({
        dom.subscribe_dom_click(fn(_) { msg.None }, msg.UserClosedModal)
      })
    }

    msg.UserClosedModal -> {
      let new_model = Data(..data, display_modal: data.NoModal)
      #(new_model, effect.none())
    }

    msg.ApiReturnedProposalsQuestions(questions:, data_sources:, data_points:) -> {
      use <- require_not_modifying_question(data)
      let filters =
        proposal.ProposalFilters(status: proposal.AllStatus, owner: option.None)
      data
      |> data.mark_as_loaded(loading.questions_loaded)
      |> data.add_proposal_questions(questions)
      |> data.set_proposal_filters(filters)
      |> data.close_filter_proposal
      |> data.add_questions_data_sources(data_sources)
      |> data.add_questions_data_points(data_points)
      |> pair.new(effect.none())
    }

    msg.ApiTimeoutedProposalsQuestions -> {
      data
      |> data.mark_as_loaded(loading.questions_loaded)
      |> pair.new(effect.none())
    }

    msg.OnCopilotThreads(#(questions, data_sources, data_points)) -> {
      use <- require_not_modifying_question(data)
      data
      |> data.add_copilot_questions(questions)
      |> data.add_questions_data_sources(data_sources)
      |> data.add_questions_data_points(data_points)
      |> pair.new(effect.none())
    }

    msg.ApplicationQueriedProposalQuestions(timeout, proposal_id) -> {
      case data.route {
        route.Projects(route.ProjectProposals(_, route.ShowProposal(pid, ..)))
          if pid == proposal_id
        ->
          data
          |> data.mark_as_loaded(loading.questions_loading)
          |> pair.new({
            question_effects.query_proposal_questions(data, timeout, pid)
          })
        _ -> #(data, effect.none())
      }
    }

    msg.OnRouteChange(route) -> {
      let new_data = Data(..data, route: route.from_uri(route))
      new_data
      |> data.empty_opened_projects
      |> data.empty_proposal_builder
      |> data.empty_popup("all")
      |> pair.new({
        effect.batch([
          window.update_page_title(new_data.route),
          effects.on_route(new_data, data.route),
        ])
      })
    }

    msg.AuthStateChanged(user) -> {
      case user {
        option.None ->
          route.get_project_id(data.route)
          |> data.new(data.client, data.route, data.collapsed_navbar, _)
          |> pair.new(effect.none())
        Some(#(access_token, user)) -> {
          data
          |> data.update_access_token(access_token)
          |> data.update_connected_user(user)
          |> fn(data) { #(data, effects.on_connected_user(data)) }
        }
      }
    }

    msg.DocumentReturnedMoreButtonUnsubscriber(unsubscriber) -> {
      let more_proposal_unsubscriber = Some(unsubscriber)
      Data(..data, more_proposal_unsubscriber:)
      |> pair.new(effect.none())
    }

    msg.UserToggledQuestionMoreButton(question_id:) -> {
      case data.more_proposal_opened {
        Some(qid) if qid == question_id -> #(
          Data(..data, more_proposal_opened: option.None),
          model.unsubscribe_more_proposal(data),
        )
        Some(_) -> #(
          Data(..data, more_proposal_opened: Some(question_id)),
          model.unsubscribe_more_proposal(data),
        )
        option.None -> #(
          Data(..data, more_proposal_opened: Some(question_id)),
          msg.DocumentReturnedMoreButtonUnsubscriber
            |> dom.subscribe_dom_click(msg.ClosePopups("ai-" <> question_id)),
        )
      }
    }

    msg.UserSelectedLanguage(language) -> {
      Data(..data, language:)
      |> pair.new(effect.none())
    }

    msg.ApiRejectedProposal(proposal) -> {
      data.upsert_proposals(data, [proposal])
      |> pair.new({
        [
          "Your proposal modification has not been handled.",
          "Please, retry later.",
        ]
        |> string.join(" ")
        |> toast.error
      })
    }

    msg.ApiReturnedProposals(proposals) -> {
      use <- require_not_modifying_question(data)
      data.upsert_proposals(data, proposals)
      |> pair.new(effect.none())
    }

    msg.ApiReturnedProjects(projects) -> {
      use <- require_not_modifying_question(data)
      data.upsert_projects(data, projects)
      |> pair.new(effect.none())
    }

    msg.ApiReturnedProject(project) -> {
      use <- require_not_modifying_question(data)
      data.upsert_projects(data, [project])
      |> pair.new(effect.none())
    }

    msg.ApiDeletedProject(project_id) -> {
      use <- require_not_modifying_question(data)
      data.delete_project(data, project_id)
      |> pair.new(effect.none())
    }

    msg.Auth0(auth0) ->
      case auth0 {
        auth0.Authorize(_) -> #(data, effect.none())
        auth0.Authorized(_) -> #(data, effect.none())
        auth0.Login -> {
          let handler = case data.route {
            route.SlackLogin(..) -> auth0.login_with_popup
            _ -> auth0.login_with_redirect
          }
          data.client
          |> handler(msg.AuthStateChanged)
          |> pair.new(data, _)
        }
        auth0.Logout ->
          data.client
          |> auth0.logout(msg.AuthStateChanged)
          |> pair.new(data, _)
      }

    msg.OnConnectors(msg) -> {
      use <- require_not_modifying_question(data)
      connectors.handle_on_connectors(data, msg)
    }

    msg.FetchUserData -> #(data, effects.fetch_user_data(data))

    msg.MsgHandledProposalBuilder(msg) -> update_proposal_builder(data, msg)

    msg.OnCopilotInput(content) ->
      data
      |> data.update_copilot_input(content)
      |> pair.new(effect.none())

    msg.UserEdittedCustomRewording(content) ->
      data
      |> data.update_custom_rewording_input(content)
      |> pair.new(effect.none())

    msg.ContentLibrary(msg) -> update_content_library(data, msg)

    msg.UserUpdatedProposalQuestion(question:, content:) -> {
      let translation_id =
        translated_text.id_builder(question.id, data.language, translate.Answer)
      let optimistic_question = question.update_custom_answer(question, content)
      data
      |> case dict.get(data.translations, translation_id) {
        Error(_) -> function.identity
        Ok(_) -> {
          let translation =
            translate.Translation(
              id: translation_id,
              target_id: question.id,
              content: content,
              language: data.language,
              metadata: translate.TranslationMetadata(translate.Answer),
            )
          data.upsert_translations(_, [translation])
        }
      }
      |> question_effects.prepare_updating_question(question, content)
      |> data.upsert_proposal_question(optimistic_question)
      |> pair.new(question_effects.update_question(data, question, content))
    }

    msg.UserUpdatedProposalQuestionYesNo(question:, yes_no:) ->
      data
      |> question_effects.prepare_updating_question_yes_no(question, yes_no)
      |> pair.new({
        question_effects.update_question_yes_no(data, question, yes_no)
      })

    msg.UserUpdatedProposalQuestionChoice(question:, choice:) -> {
      data
      |> question_effects.prepare_updating_question_choice(question, choice)
      |> pair.new({
        question_effects.update_question_choice(data, question, choice)
      })
    }

    msg.ApiReturnedProposalQuestion(question:) ->
      data
      |> data.upsert_proposal_question(question)
      |> pair.new(effect.none())

    msg.ApiRejectedProposalQuestion(question:) ->
      data
      |> data.upsert_proposal_question(question)
      |> pair.new(toast.error("Unable to update question. Please, retry later."))

    msg.OnCopilotQuestionUpdated(questions_with_data_sources_and_points:) -> {
      let #(question, data_sources, data_points) =
        questions_with_data_sources_and_points
      let data_sources = dict.new() |> dict.insert(question.id, data_sources)
      let data_points = dict.new() |> dict.insert(question.id, data_points)
      data
      |> data.add_copilot_questions([question])
      |> data.add_questions_data_sources(data_sources)
      |> data.add_questions_data_points(data_points)
      |> pair.new(effect.none())
    }

    msg.OpenLink(link: option.None) -> #(data, effect.none())
    msg.OpenLink(link: Some(#(filename, link))) -> {
      #(data, content_library_effects.open_link(data, filename, link))
    }

    msg.UserUpdatedProjectTitle(id, name) -> {
      case list.key_find(data.projects, id) {
        Error(_) -> #(data, effect.none())
        Ok(proposal) -> {
          project.Project(..proposal, name:)
          |> list.key_set(data.projects, id, _)
          |> fn(projects) { Data(..data, projects:) }
          |> pair.new(effect.none())
        }
      }
    }

    msg.UserSavedProjectTitle(id) -> {
      effect.batch([
        effects.blur_active_element(),
        project_effects.update_project(data, id),
      ])
      |> pair.new(data, _)
    }

    msg.DisplayToast(level:, message:) -> #(data, {
      toast.options()
      |> toast.level(level)
      |> toast.custom(message)
    })

    msg.HideToast(id) -> #(data, toast.hide(id))

    msg.Xlsx(xlsx) -> handle_xlsx(data, xlsx)

    msg.OnCopilotSubmit -> {
      let thread_id = ffi.uuid()
      route.push(route.CoPilot(route.AiResponse(thread_id)))
      |> list.prepend([copilot_effects.submit_copilot(data, thread_id)], _)
      |> effect.batch
      |> pair.new(data.reset_copilot_input(data), _)
    }

    msg.OnUsers(users) -> {
      use <- require_not_modifying_question(data)
      Data(..data, users:)
      |> data.mark_as_loaded(loading.users)
      |> pair.new(effect.none())
    }

    msg.ToggleProposalCollaboratorsPopup(question_id) -> {
      let qid = Some(question_id)
      let is_question = data.collaborators_proposal_opened == qid
      let collaborators_proposal_opened = case is_question {
        True -> option.None
        False -> qid
      }
      Data(..data, collaborators_proposal_opened:)
      |> pair.new(case collaborators_proposal_opened {
        option.None -> effect.none()
        Some(_) ->
          dom.subscribe_dom_click(
            fn(_) { msg.None },
            msg.ClosePopups("collaborator-" <> question_id),
          )
      })
    }

    msg.AddQuestionOwner(question_id:, proposal_id:, user_id:) -> {
      let uid = Some(user_id)
      case model.find_question(data, proposal_id:, question_id:) {
        Error(_) -> #(data, effect.none())
        Ok(Question(owner:, ..)) if owner == uid -> #(data, effect.none())
        Ok(question) -> {
          let question = Question(..question, owner: uid, validated: False)
          let upsert = msg.UpsertQuestion(question)
          use e <- pair.map_second(update_data(data, upsert))
          let eff =
            question_effects.update_question_owner(data:, question:, user_id:)
          effect.batch([e, eff])
        }
      }
    }

    msg.OnUserFunctionSelected(user_id:, function:) -> {
      let users = {
        let function = Some(function)
        use u <- list.map(data.users)
        use <- bool.guard(when: u.id != user_id, return: u)
        user.User(..u, function:)
      }
      Data(..data, users:)
      |> pair.new(organization_effects.update_user_function(
        data,
        user_id,
        function,
      ))
    }

    msg.UserClickedAiRewrite(proposal_id:, question_id:, asked:) -> {
      let question = model.find_question(data, proposal_id, question_id)
      case question {
        Error(_) -> #(data, effect.none())
        Ok(question) -> {
          let question = question.Question(..question, ai_processing: True)
          data
          |> data.empty_popup("ai-" <> question_id)
          |> update_data(msg.UpsertQuestion(question))
          |> pair.map_second(fn(e) {
            [
              model.unsubscribe_more_proposal(data),
              question_effects.ask_ai_rewording(
                data:,
                proposal_id:,
                question_id:,
                asked:,
                custom: "",
              ),
            ]
            |> list.prepend(e)
            |> effect.batch()
          })
        }
      }
    }

    msg.UserClickedAddToQuestionBank(proposal_id:, question_id:) -> {
      let question = model.find_question(data, proposal_id, question_id)
      case question {
        Error(_) -> #(data, effect.none())
        Ok(question) -> {
          let answer =
            question.answer.custom
            |> option.or(question.answer.long)
            |> option.unwrap("")
          let question = question.content
          let new_qna =
            content_library.NewQna(
              question:,
              answer:,
              existing_document: option.None,
              loading: False,
            )
          content_library.ContentLibrary(..data.content_library, new_qna:)
          |> data.set_content_library(data, _)
          |> data.empty_popup("ai-" <> question_id)
          |> update_content_library(msg.UserSubmittedQuestionBankEdit)
          |> pair.map_second(fn(e) {
            effect.batch([model.unsubscribe_more_proposal(data), e])
          })
        }
      }
    }

    msg.UserToggledProjectCollaborator(project_id:, user_id:) -> {
      data.projects
      |> list.key_find(project_id)
      |> result.map(project.toggle_collaborator(_, user_id))
      |> result.map(list.wrap)
      |> result.map(data.upsert_projects(data, _))
      |> result.unwrap(data)
      |> pair.new({
        project_effects.toggle_project_collaborator(data, project_id, user_id)
      })
    }

    msg.UserSubmittedCustomRewording(proposal_id:, question_id:) -> {
      let custom_rewording_input = data.custom_rewording_input
      use <- bool.guard(when: custom_rewording_input == "", return: #(
        data,
        effect.none(),
      ))
      let question = model.find_question(data, proposal_id, question_id)
      case question {
        Error(_) -> #(data, effect.none())
        Ok(question) -> {
          let question = question.Question(..question, ai_processing: True)
          data
          |> data.empty_popup("ai-" <> question_id)
          |> data.reset_custom_rewording_input
          |> update_data(msg.UpsertQuestion(question))
          |> pair.map_second(fn(e) {
            [
              model.unsubscribe_more_proposal(data),
              question_effects.ask_ai_rewording(
                data:,
                proposal_id:,
                question_id:,
                asked: "custom",
                custom: custom_rewording_input,
              ),
            ]
            |> list.prepend(e)
            |> effect.batch()
          })
        }
      }
    }

    msg.UserUpdatedProposalFiltersStatus(status) -> {
      proposal.ProposalFilters(..data.proposal_filters, status:)
      |> data.set_proposal_filters(data, _)
      |> pair.new(effect.none())
    }

    msg.UserUpdatedProposalFiltersOwner(owner) -> {
      proposal.ProposalFilters(..data.proposal_filters, owner:)
      |> data.set_proposal_filters(data, _)
      |> pair.new(effect.none())
    }

    msg.ToggleFeed -> {
      let feed_opened = !data.feed_opened
      #(Data(..data, feed_opened:), case feed_opened {
        True ->
          dom.subscribe_dom_click(fn(_) { msg.None }, msg.ClosePopups("feed"))
        False -> effect.none()
      })
    }
    msg.UserToggledFilterProposal -> {
      proposal.ProposalFilters(status: proposal.AllStatus, owner: option.None)
      |> data.set_proposal_filters(data, _)
      |> data.toggle_filter_proposal_opened()
      |> pair.new(case data.filter_proposal_opened {
        False -> effect.none()
        True ->
          msg.ClosePopups("proposal-filter")
          |> dom.subscribe_dom_click(fn(_) { msg.None }, _)
      })
    }

    msg.UpdateNotifications(notifications:) -> {
      use <- require_not_modifying_question(data)
      data
      |> data.set_notifications(notifications)
      |> pair.new(effect.none())
    }

    msg.UpdateNotification(notification:) ->
      data
      |> data.set_notification(notification)
      |> pair.new(effect.none())

    msg.MarkAllNotificationsAsRead ->
      data.notifications
      |> list.map(fn(n) { Notification(..n, read: True) })
      |> data.set_notifications(data, _)
      |> pair.new(user_effects.mark_all_notifications_as_read(data))

    msg.UserClickedNotification(notification:) -> {
      let notification_id = notification.id
      let effs = case notification.content {
        notification.ProposalAssigned(proposal_id:, project_id:) ->
          route.ShowProposal(proposal_id, option.None, 0)
          |> route.ProjectProposals(project_id, _)
          |> route.Projects
          |> route.push
        notification.ProjectAssigned(project_id:) ->
          route.ShowProject(project_id)
          |> route.Projects
          |> route.push
        notification.Question(proposal_id:, question_id:, project_id:) -> {
          effect.batch([
            route.ShowProposal(proposal_id, option.None, 0)
              |> route.ProjectProposals(project_id, _)
              |> route.Projects
              |> route.push,
            effect.from(fn(_) { ffi.scroll_to_question(question_id:) }),
          ])
        }
      }
      let notification =
        list.find(data.notifications, fn(n) { n.id == notification_id })
      data
      |> data.empty_popup("all")
      |> case notification {
        Ok(n) -> data.set_notification(_, Notification(..n, read: True))
        Error(_) -> function.identity
      }
      |> pair.new(
        effect.batch([
          effs,
          user_effects.mark_notification_as_read(data, notification_id),
        ]),
      )
    }

    msg.ClosePopups(id) -> {
      data
      |> data.empty_popup(id)
      |> pair.new(model.unsubscribe_more_proposal(data))
    }

    msg.MarkNotificationAsRead(notification_id:) -> {
      let eff = user_effects.mark_notification_as_read(data, notification_id)
      case list.find(data.notifications, fn(n) { n.id == notification_id }) {
        Error(_) -> #(data, eff)
        Ok(notification) -> {
          Notification(..notification, read: True)
          |> data.set_notification(data, _)
          |> pair.new(eff)
        }
      }
    }

    msg.ToastUser(options:, content:) -> {
      #(data, toast.custom(options, content))
    }

    msg.UpsertQuestion(question:) -> {
      case dict.get(data.questions.by_proposal, question.proposal_id) {
        Error(_) ->
          [#(question.id, question)]
          |> data.set_questions(data, question.proposal_id, _)
          |> pair.new(effect.none())
        Ok(questions) -> {
          list.key_set(questions, question.id, question)
          |> data.set_questions(data, question.proposal_id, _)
          |> pair.new(effect.none())
        }
      }
    }

    msg.UserValidatedQuestion(question:) -> {
      case list.contains(data.running_requests, question.id) {
        True -> #(data, {
          use dispatch <- effect.from
          let retry = fn() { dispatch(msg.UserValidatedQuestion(question:)) }
          global.set_timeout(1000, retry)
          Nil
        })

        False -> {
          let question.Question(proposal_id:, ..) = question
          let found =
            dict.get(data.questions.by_proposal, proposal_id) |> result.is_ok
          use <- bool.guard(when: !found, return: #(data, effect.none()))
          let question = question.Question(..question, validated: True)
          update_data(data, msg.UpsertQuestion(question))
          |> pair.map_second(fn(e) {
            [e, question_effects.mark_question_as_validated(data, question)]
            |> effect.batch
          })
        }
      }
    }

    msg.UpdateTags(tags:) -> {
      use <- require_not_modifying_question(data)
      Data(..data, tags:)
      |> pair.new(effect.none())
    }

    msg.WebsocketReceivedMessage(value) -> {
      messages.decode_incoming_message(value)
      |> result.map(handle_received_message(data, _))
      |> result.unwrap(data)
      |> pair.new(effect.none())
    }

    msg.ApiReturnedQualificationMatrix(qualification_matrix:) -> {
      Data(..data, qualification_matrix:)
      |> pair.new(effect.none())
    }

    msg.UserSelectedPage(page) -> {
      case data.route {
        route.Projects(route.ProjectProposals(id, route.ShowProposal(..) as r)) ->
          route.ShowProposal(r.id, r.sheet, page)
          |> route.ProjectProposals(id, _)
          |> route.Projects
          |> route.replace
        _ -> effect.none()
      }
      |> pair.new(data, _)
    }
    msg.ApiReturnedTranslations(t) -> {
      #(data.upsert_translations(data, t), effect.none())
    }

    msg.None -> #(data, effect.none())
  }
}

fn handle_received_message(data: Data, msg: messages.ApiSentMessage) {
  case msg {
    messages.ApiSentProposalUpdated(proposal) ->
      data.upsert_proposals(data, [proposal])
    messages.ApiSentConnectorRegistered(connector, registered) ->
      msg.ApiReturnedConnectorData(connector, registered)
      |> connectors.handle_on_connectors(data, _)
      |> pair.first
    messages.ApiSentConnectorSettingsUpdated(connector_settings) ->
      msg.ApiReturnedConnectorSettings([connector_settings])
      |> connectors.handle_on_connectors(data, _)
      |> pair.first
    messages.ApiSentNotificationUpdated(notification) ->
      data.set_notification(data, notification)
    messages.ApiSentDataSourceUpdated(data_source) ->
      content_library.upsert_data_source(data.content_library, data_source)
      |> data.set_content_library(data, _)
    messages.ApiSentUserDataUpdated(user_id, _org_id, function) ->
      data.update_org_user(data, user_id, function)
    messages.ApiSentQuestionUpdated(question, data_sources, data_points) -> {
      let data_sources = dict.from_list([#(question.id, data_sources)])
      let data_points = dict.from_list([#(question.id, data_points)])
      data.upsert_proposal_question(data, question)
      |> data.add_questions_data_sources(data_sources)
      |> data.add_questions_data_points(data_points)
    }
    messages.ApiSentTranslation(translation) -> {
      data.upsert_translations(data, [translation])
    }
  }
}

fn handle_xlsx(data: Data, msg: msg.Xlsx) {
  case msg {
    msg.DownloadXlsx(proposal_id:, toast_id:) -> {
      let proposal = data.proposals |> list.key_find(proposal_id)
      use proposal <- result.try(proposal)
      let project = data.projects |> list.key_find(proposal.project_id)
      use project <- result.map(project)
      proposal_effects.download_xlsx(data, proposal.id, toast_id, project.name)
    }
    msg.GenerateXlsx(proposal_id:) -> {
      let proposal = list.key_find(data.proposals, proposal_id)
      use proposal <- result.map(proposal)
      let name = "\"" <> proposal.name <> "\""
      let generating = "Generating XLSX file for proposal " <> name <> "."
      let msg = [generating, "Please, wait…"] |> string.join("\n")
      toast.options()
      |> toast.sticky()
      |> toast.notify(fn(t) { msg.Xlsx(msg.DownloadXlsx(proposal_id, t)) })
      |> toast.custom(msg)
    }
  }
  |> result.unwrap(effect.none())
  |> pair.new(data, _)
}

fn view(model: Model, stylesheet: magic.StyleSheet) {
  use <- magic.render([stylesheet])
  let #(data, _) = bright.unwrap(model)
  case data.route, data.is_connected(data) {
    route.Login(..), _ -> login.invite()
    _, False -> login.view()
    route.SlackLogin(..), True -> slack_login.view(model)
    _, True -> layout.view(model)
  }
}
