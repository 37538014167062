/// <reference types="./content_library.d.mts" />
import * as $birl from "../../birl/birl.mjs";
import * as $connector from "../../common/data/connector.mjs";
import { SteerlabQna } from "../../common/data/connector.mjs";
import * as $data_source from "../../common/data/data_source.mjs";
import { DataSource, StatusStats } from "../../common/data/data_source.mjs";
import * as $spreadsheet_selector from "../../common/data/ephemeral/spreadsheet_selector.mjs";
import * as $tag from "../../common/data/tag.mjs";
import * as $extra from "../../common/gleam/list/extra.mjs";
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $msg from "../data/msg.mjs";
import { toList, prepend as listPrepend, CustomType as $CustomType, isEqual } from "../gleam.mjs";
import * as $utils from "../utils.mjs";

export class ContentLibrary extends $CustomType {
  constructor(change_expiration_date_input, change_expiration_date_popup_opened, change_owner_popup_opened, change_status_popup_opened, change_tags_popup_opened, dropdown_unsubscriber, filter_selected, ingestion_loading, ingestion_spreadsheet, new_qna, non_qna_sources, non_qna_status, qna_sources, qna_status, search, selected) {
    super();
    this.change_expiration_date_input = change_expiration_date_input;
    this.change_expiration_date_popup_opened = change_expiration_date_popup_opened;
    this.change_owner_popup_opened = change_owner_popup_opened;
    this.change_status_popup_opened = change_status_popup_opened;
    this.change_tags_popup_opened = change_tags_popup_opened;
    this.dropdown_unsubscriber = dropdown_unsubscriber;
    this.filter_selected = filter_selected;
    this.ingestion_loading = ingestion_loading;
    this.ingestion_spreadsheet = ingestion_spreadsheet;
    this.new_qna = new_qna;
    this.non_qna_sources = non_qna_sources;
    this.non_qna_status = non_qna_status;
    this.qna_sources = qna_sources;
    this.qna_status = qna_status;
    this.search = search;
    this.selected = selected;
  }
}

export class NewQna extends $CustomType {
  constructor(answer, existing_document, loading, question) {
    super();
    this.answer = answer;
    this.existing_document = existing_document;
    this.loading = loading;
    this.question = question;
  }
}

export function new_qna() {
  let existing_document = new $option.None();
  return new NewQna("", existing_document, false, "");
}

export function set_filter(content_library, filter_selected) {
  let _record = content_library;
  return new ContentLibrary(
    _record.change_expiration_date_input,
    _record.change_expiration_date_popup_opened,
    _record.change_owner_popup_opened,
    _record.change_status_popup_opened,
    _record.change_tags_popup_opened,
    _record.dropdown_unsubscriber,
    filter_selected,
    _record.ingestion_loading,
    _record.ingestion_spreadsheet,
    _record.new_qna,
    _record.non_qna_sources,
    _record.non_qna_status,
    _record.qna_sources,
    _record.qna_status,
    _record.search,
    _record.selected,
  );
}

function do_upsert_data_source(sources, source) {
  if (sources.atLeastLength(1) && (sources.head.id === source.id)) {
    let s = sources.head;
    let rest = sources.tail;
    return listPrepend(source, rest);
  } else if (sources.atLeastLength(1)) {
    let s = sources.head;
    let rest = sources.tail;
    return listPrepend(s, do_upsert_data_source(rest, source));
  } else {
    return toList([source]);
  }
}

export function upsert_data_source(content_library, source) {
  let $ = source.kind;
  if ($ instanceof SteerlabQna) {
    let _pipe = content_library.qna_sources;
    let _pipe$1 = do_upsert_data_source(_pipe, source);
    return ((s) => {
      let _record = content_library;
      return new ContentLibrary(
        _record.change_expiration_date_input,
        _record.change_expiration_date_popup_opened,
        _record.change_owner_popup_opened,
        _record.change_status_popup_opened,
        _record.change_tags_popup_opened,
        _record.dropdown_unsubscriber,
        _record.filter_selected,
        _record.ingestion_loading,
        _record.ingestion_spreadsheet,
        _record.new_qna,
        _record.non_qna_sources,
        _record.non_qna_status,
        s,
        _record.qna_status,
        _record.search,
        _record.selected,
      );
    })(_pipe$1);
  } else {
    let _pipe = content_library.non_qna_sources;
    let _pipe$1 = do_upsert_data_source(_pipe, source);
    return ((s) => {
      let _record = content_library;
      return new ContentLibrary(
        _record.change_expiration_date_input,
        _record.change_expiration_date_popup_opened,
        _record.change_owner_popup_opened,
        _record.change_status_popup_opened,
        _record.change_tags_popup_opened,
        _record.dropdown_unsubscriber,
        _record.filter_selected,
        _record.ingestion_loading,
        _record.ingestion_spreadsheet,
        _record.new_qna,
        s,
        _record.non_qna_status,
        _record.qna_sources,
        _record.qna_status,
        _record.search,
        _record.selected,
      );
    })(_pipe$1);
  }
}

export function set_data_sources(cl, sources) {
  let qna_sources = $list.filter(
    sources,
    (s) => { return isEqual(s.kind, new SteerlabQna()); },
  );
  let non_qna_sources = $list.filter(
    sources,
    (s) => { return !isEqual(s.kind, new SteerlabQna()); },
  );
  let _record = cl;
  return new ContentLibrary(
    _record.change_expiration_date_input,
    _record.change_expiration_date_popup_opened,
    _record.change_owner_popup_opened,
    _record.change_status_popup_opened,
    _record.change_tags_popup_opened,
    _record.dropdown_unsubscriber,
    _record.filter_selected,
    _record.ingestion_loading,
    _record.ingestion_spreadsheet,
    _record.new_qna,
    non_qna_sources,
    _record.non_qna_status,
    qna_sources,
    _record.qna_status,
    _record.search,
    _record.selected,
  );
}

function set_tags(source, tag, add_remove) {
  let _record = source;
  return new DataSource(
    _record.id,
    _record.status,
    _record.display,
    _record.kind,
    _record.authors,
    _record.owner,
    _record.document_id,
    _record.blob_link,
    _record.content_link,
    _record.hash,
    _record.version,
    (() => {
      if (add_remove instanceof $msg.Remove) {
        return $list.filter(source.tags, (t) => { return t !== tag.id; });
      } else {
        let _pipe = source.tags;
        let _pipe$1 = $extra.postpend(_pipe, tag.id);
        return $list.unique(_pipe$1);
      }
    })(),
    _record.org_id,
    _record.expiration_date,
  );
}

export function select(content_library, id) {
  let _record = content_library;
  return new ContentLibrary(
    _record.change_expiration_date_input,
    _record.change_expiration_date_popup_opened,
    _record.change_owner_popup_opened,
    _record.change_status_popup_opened,
    _record.change_tags_popup_opened,
    _record.dropdown_unsubscriber,
    _record.filter_selected,
    _record.ingestion_loading,
    _record.ingestion_spreadsheet,
    _record.new_qna,
    _record.non_qna_sources,
    _record.non_qna_status,
    _record.qna_sources,
    _record.qna_status,
    _record.search,
    $dict.insert(content_library.selected, id, true),
  );
}

export function deselect(content_library, id) {
  let _record = content_library;
  return new ContentLibrary(
    _record.change_expiration_date_input,
    _record.change_expiration_date_popup_opened,
    _record.change_owner_popup_opened,
    _record.change_status_popup_opened,
    _record.change_tags_popup_opened,
    _record.dropdown_unsubscriber,
    _record.filter_selected,
    _record.ingestion_loading,
    _record.ingestion_spreadsheet,
    _record.new_qna,
    _record.non_qna_sources,
    _record.non_qna_status,
    _record.qna_sources,
    _record.qna_status,
    _record.search,
    $dict.delete$(content_library.selected, id),
  );
}

export function deselect_all(content_library) {
  let _record = content_library;
  return new ContentLibrary(
    _record.change_expiration_date_input,
    _record.change_expiration_date_popup_opened,
    _record.change_owner_popup_opened,
    _record.change_status_popup_opened,
    _record.change_tags_popup_opened,
    _record.dropdown_unsubscriber,
    _record.filter_selected,
    _record.ingestion_loading,
    _record.ingestion_spreadsheet,
    _record.new_qna,
    _record.non_qna_sources,
    _record.non_qna_status,
    _record.qna_sources,
    _record.qna_status,
    _record.search,
    $dict.new$(),
  );
}

export function is_selected(content_library, id) {
  let _pipe = $dict.get(content_library.selected, id);
  return $result.unwrap(_pipe, false);
}

function update_sources(content_library, sources, mapper) {
  return $list.map(
    sources,
    (source) => {
      let is_selected$1 = is_selected(content_library, source.id);
      return $bool.guard(
        !is_selected$1,
        source,
        () => { return mapper(source); },
      );
    },
  );
}

function update_selected_sources(content_library, mapper) {
  let sources = content_library.non_qna_sources;
  let _record = content_library;
  return new ContentLibrary(
    _record.change_expiration_date_input,
    _record.change_expiration_date_popup_opened,
    _record.change_owner_popup_opened,
    _record.change_status_popup_opened,
    _record.change_tags_popup_opened,
    _record.dropdown_unsubscriber,
    _record.filter_selected,
    _record.ingestion_loading,
    _record.ingestion_spreadsheet,
    _record.new_qna,
    update_sources(content_library, sources, mapper),
    _record.non_qna_status,
    _record.qna_sources,
    _record.qna_status,
    _record.search,
    _record.selected,
  );
}

function update_selected_qna_sources(content_library, mapper) {
  let sources = content_library.qna_sources;
  let _record = content_library;
  return new ContentLibrary(
    _record.change_expiration_date_input,
    _record.change_expiration_date_popup_opened,
    _record.change_owner_popup_opened,
    _record.change_status_popup_opened,
    _record.change_tags_popup_opened,
    _record.dropdown_unsubscriber,
    _record.filter_selected,
    _record.ingestion_loading,
    _record.ingestion_spreadsheet,
    _record.new_qna,
    _record.non_qna_sources,
    _record.non_qna_status,
    update_sources(content_library, sources, mapper),
    _record.qna_status,
    _record.search,
    _record.selected,
  );
}

export function attribute_source(content_library, user_id) {
  let set_owner = (s) => {
    let _record = s;
    return new DataSource(
      _record.id,
      _record.status,
      _record.display,
      _record.kind,
      _record.authors,
      new $option.Some(user_id),
      _record.document_id,
      _record.blob_link,
      _record.content_link,
      _record.hash,
      _record.version,
      _record.tags,
      _record.org_id,
      _record.expiration_date,
    );
  };
  let _pipe = content_library;
  let _pipe$1 = update_selected_sources(_pipe, set_owner);
  let _pipe$2 = update_selected_qna_sources(_pipe$1, set_owner);
  return deselect_all(_pipe$2);
}

export function attribute_status(content_library, status) {
  let set_status = (s) => {
    let _record = s;
    return new DataSource(
      _record.id,
      status,
      _record.display,
      _record.kind,
      _record.authors,
      _record.owner,
      _record.document_id,
      _record.blob_link,
      _record.content_link,
      _record.hash,
      _record.version,
      _record.tags,
      _record.org_id,
      _record.expiration_date,
    );
  };
  let _pipe = content_library;
  let _pipe$1 = update_selected_sources(_pipe, set_status);
  let _pipe$2 = update_selected_qna_sources(_pipe$1, set_status);
  return deselect_all(_pipe$2);
}

export function attribute_tag(content_library, tag, add_remove) {
  let set_tags$1 = (_capture) => { return set_tags(_capture, tag, add_remove); };
  let _pipe = content_library;
  let _pipe$1 = update_selected_sources(_pipe, set_tags$1);
  return update_selected_qna_sources(_pipe$1, set_tags$1);
}

export function attribute_expiration_date(content_library, expiration_date) {
  let expiration_date$1 = new $option.Some(expiration_date);
  let set_expiration_date = (ds) => {
    let _record = ds;
    return new DataSource(
      _record.id,
      _record.status,
      _record.display,
      _record.kind,
      _record.authors,
      _record.owner,
      _record.document_id,
      _record.blob_link,
      _record.content_link,
      _record.hash,
      _record.version,
      _record.tags,
      _record.org_id,
      expiration_date$1,
    );
  };
  let _pipe = content_library;
  let _pipe$1 = update_selected_sources(_pipe, set_expiration_date);
  let _pipe$2 = update_selected_qna_sources(_pipe$1, set_expiration_date);
  return deselect_all(_pipe$2);
}

function new_status() {
  return new StatusStats(0, 0, 0, 0);
}

export function new$() {
  let now = $birl.utc_now();
  return new ContentLibrary(
    $utils.time_to_normalized_date(now, "-"),
    false,
    false,
    false,
    false,
    new $option.None(),
    new $option.None(),
    false,
    $spreadsheet_selector.new$(),
    new_qna(),
    toList([]),
    new_status(),
    toList([]),
    new_status(),
    "",
    $dict.new$(),
  );
}

export function toggle_change_owner(content_library) {
  let _record = content_library;
  return new ContentLibrary(
    _record.change_expiration_date_input,
    false,
    !content_library.change_owner_popup_opened,
    false,
    false,
    _record.dropdown_unsubscriber,
    _record.filter_selected,
    _record.ingestion_loading,
    _record.ingestion_spreadsheet,
    _record.new_qna,
    _record.non_qna_sources,
    _record.non_qna_status,
    _record.qna_sources,
    _record.qna_status,
    _record.search,
    _record.selected,
  );
}

export function toggle_change_expiration_date(content_library) {
  let now = $birl.utc_now();
  let _record = content_library;
  return new ContentLibrary(
    $utils.time_to_normalized_date(now, "-"),
    !content_library.change_expiration_date_popup_opened,
    false,
    false,
    false,
    _record.dropdown_unsubscriber,
    _record.filter_selected,
    _record.ingestion_loading,
    _record.ingestion_spreadsheet,
    _record.new_qna,
    _record.non_qna_sources,
    _record.non_qna_status,
    _record.qna_sources,
    _record.qna_status,
    _record.search,
    _record.selected,
  );
}

export function toggle_change_status(content_library) {
  let _record = content_library;
  return new ContentLibrary(
    _record.change_expiration_date_input,
    false,
    false,
    !content_library.change_status_popup_opened,
    false,
    _record.dropdown_unsubscriber,
    _record.filter_selected,
    _record.ingestion_loading,
    _record.ingestion_spreadsheet,
    _record.new_qna,
    _record.non_qna_sources,
    _record.non_qna_status,
    _record.qna_sources,
    _record.qna_status,
    _record.search,
    _record.selected,
  );
}

export function toggle_change_tags(content_library) {
  let _record = content_library;
  return new ContentLibrary(
    _record.change_expiration_date_input,
    false,
    false,
    false,
    !content_library.change_tags_popup_opened,
    _record.dropdown_unsubscriber,
    _record.filter_selected,
    _record.ingestion_loading,
    _record.ingestion_spreadsheet,
    _record.new_qna,
    _record.non_qna_sources,
    _record.non_qna_status,
    _record.qna_sources,
    _record.qna_status,
    _record.search,
    _record.selected,
  );
}

export function close_popups(content_library) {
  let _record = content_library;
  return new ContentLibrary(
    _record.change_expiration_date_input,
    false,
    false,
    false,
    false,
    _record.dropdown_unsubscriber,
    _record.filter_selected,
    _record.ingestion_loading,
    _record.ingestion_spreadsheet,
    _record.new_qna,
    _record.non_qna_sources,
    _record.non_qna_status,
    _record.qna_sources,
    _record.qna_status,
    _record.search,
    _record.selected,
  );
}

export function should_subscribe_dom_click(content_library) {
  return ((content_library.change_expiration_date_popup_opened || content_library.change_owner_popup_opened) || content_library.change_status_popup_opened) || content_library.change_tags_popup_opened;
}

export function reset_ingestion_spreadsheet(content_library) {
  let ingestion_loading = false;
  let ingestion_spreadsheet = $spreadsheet_selector.new$();
  let _record = content_library;
  return new ContentLibrary(
    _record.change_expiration_date_input,
    _record.change_expiration_date_popup_opened,
    _record.change_owner_popup_opened,
    _record.change_status_popup_opened,
    _record.change_tags_popup_opened,
    _record.dropdown_unsubscriber,
    _record.filter_selected,
    ingestion_loading,
    ingestion_spreadsheet,
    _record.new_qna,
    _record.non_qna_sources,
    _record.non_qna_status,
    _record.qna_sources,
    _record.qna_status,
    _record.search,
    _record.selected,
  );
}

export function set_new_qna(content_library, new_qna) {
  let _record = content_library;
  return new ContentLibrary(
    _record.change_expiration_date_input,
    _record.change_expiration_date_popup_opened,
    _record.change_owner_popup_opened,
    _record.change_status_popup_opened,
    _record.change_tags_popup_opened,
    _record.dropdown_unsubscriber,
    _record.filter_selected,
    _record.ingestion_loading,
    _record.ingestion_spreadsheet,
    new_qna,
    _record.non_qna_sources,
    _record.non_qna_status,
    _record.qna_sources,
    _record.qna_status,
    _record.search,
    _record.selected,
  );
}

export function set_non_qna_status(content_library, non_qna_status) {
  let _record = content_library;
  return new ContentLibrary(
    _record.change_expiration_date_input,
    _record.change_expiration_date_popup_opened,
    _record.change_owner_popup_opened,
    _record.change_status_popup_opened,
    _record.change_tags_popup_opened,
    _record.dropdown_unsubscriber,
    _record.filter_selected,
    _record.ingestion_loading,
    _record.ingestion_spreadsheet,
    _record.new_qna,
    _record.non_qna_sources,
    non_qna_status,
    _record.qna_sources,
    _record.qna_status,
    _record.search,
    _record.selected,
  );
}

export function set_non_qna_sources(content_library, non_qna_sources) {
  let _record = content_library;
  return new ContentLibrary(
    _record.change_expiration_date_input,
    _record.change_expiration_date_popup_opened,
    _record.change_owner_popup_opened,
    _record.change_status_popup_opened,
    _record.change_tags_popup_opened,
    _record.dropdown_unsubscriber,
    _record.filter_selected,
    _record.ingestion_loading,
    _record.ingestion_spreadsheet,
    _record.new_qna,
    non_qna_sources,
    _record.non_qna_status,
    _record.qna_sources,
    _record.qna_status,
    _record.search,
    _record.selected,
  );
}

export function set_qna_sources(content_library, qna_sources) {
  let _record = content_library;
  return new ContentLibrary(
    _record.change_expiration_date_input,
    _record.change_expiration_date_popup_opened,
    _record.change_owner_popup_opened,
    _record.change_status_popup_opened,
    _record.change_tags_popup_opened,
    _record.dropdown_unsubscriber,
    _record.filter_selected,
    _record.ingestion_loading,
    _record.ingestion_spreadsheet,
    _record.new_qna,
    _record.non_qna_sources,
    _record.non_qna_status,
    qna_sources,
    _record.qna_status,
    _record.search,
    _record.selected,
  );
}

export function set_dropdown_unsubscriber(
  content_library,
  dropdown_unsubscriber
) {
  let _record = content_library;
  return new ContentLibrary(
    _record.change_expiration_date_input,
    _record.change_expiration_date_popup_opened,
    _record.change_owner_popup_opened,
    _record.change_status_popup_opened,
    _record.change_tags_popup_opened,
    dropdown_unsubscriber,
    _record.filter_selected,
    _record.ingestion_loading,
    _record.ingestion_spreadsheet,
    _record.new_qna,
    _record.non_qna_sources,
    _record.non_qna_status,
    _record.qna_sources,
    _record.qna_status,
    _record.search,
    _record.selected,
  );
}
